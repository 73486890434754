import {shuffle} from '../../utils/shuffle';
import { ExplanationModal } from '../common/explanation_modal';

export class MemoryGameChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='memory-game-challenge'>" + $('.memory-game-challenge-template').html() + "</div>";
    this.itemTemplate = $('.memory-game-challenge-item-template').html();
    this.buttonText = challenge.configuration.button_text;
    this.animating = false

    this.items = []

    this.explanationModals = {}

    challenge.configuration.items.forEach((item, i) => {
      this.items.push({
        pair: i,
        text: item.text1,
        image: item.image1,
      })

      this.items.push({
        pair: i,
        text: item.text2,
        image: item.image2,
      })

      if (item.show_explanation == 'true') {
        this.explanationModals[i] = {
          explanation: {
            items: [
              {
                audio: item.modal_audio,
                id: 'id',
                image: item.modal_image,
                text: item.modal_text,
                title: item.modal_title,
                video: item.modal_video,
              }
            ]
          }
        }
      }
    });

    this.items = shuffle(this.items);
  }

  start = () => {
    let content = this.template.replaceAll('{{TITLE}}', this.challenge.title);

    $('.challenge-container').html(content);

    $('.challenge-container .next-challenge-button').on('click', this.goToNextChallenge);

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }


    this.items.forEach((item, i) => {
      let image = '';
      let text = '';

      if (item.image) {
        image = `<span class="image"><img src="${getUrlPath(item.image)}" /></img></span>`
      }

      if (item.text) {
        text = `<div class="text">${item.text}</div>`;
      }

      let itemHtml = `<div class='item item-${i}'>` + this.itemTemplate.replaceAll('{{ITEM_TEXT}}', text) + '</div>';
      itemHtml = itemHtml.replaceAll('{{ITEM_IMAGE}}', image);

      $('.challenge-container .item-area .items-wrapper').append(itemHtml);

      const element = $(`.challenge-container .item-${i}`);

      element.data('pair', item.pair);

      const that = this;
      element.on('click', function() {
        const el = $(this);

        if (el.hasClass('selected') || el.hasClass('open')) {
          return;
        }

        that.selectedCard(el);
      })
    })
  }

  selectedCard = (element) => {
    if (this.animating) {
      return;
    }

    element.addClass('selected');

    let rightChoice = true;

    if ($('.challenge-container .item.selected').length <= 1) {
      return;
    }

    const pair = element.data('pair')

    this.animating = true;

    try {
      $('.challenge-container .item.selected').each(function() {
        const el = $(this);
        rightChoice = rightChoice && $(el).data('pair') == pair
      });

      setTimeout(() =>{
        try {
          if (rightChoice) {
            $('.challenge-container .item.selected').addClass('open')

            if (this.explanationModals[pair]) {
              var modal = new ExplanationModal(this.explanationModals[pair]);
              modal.start()
            }
          }

          $('.challenge-container .item.selected').removeClass('selected');
        } catch (error) {
          console.error('Failed to complete card animation.', error)
        }

        this.animating = false;
      }, 1000)
    } catch (error) {
      this.animating = false;
    }
  }

  goToNextChallenge = () => {
    window.goToNextChallenge()
  }

  destroy = () => {
    this.items = [];
    $('.challenge-container .next-challenge-button').off('click');
    $('.challenge-container .item').off('click');
  }
}
