$(document).on('turbolinks:request-start', () => {
  $('.global-loading-indicator').show();
});

$(document).on('turbolinks:request-end', () => {
  $('.global-loading-indicator').hide();
});

$(document).on('turbolinks:load', () => {
  window.startImageLoadingIndicator();
});
