import {shuffle} from '../../utils/shuffle';

export class BoxSortChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='box-sort-challenge'>" + $('.box-sort-challenge-template').html() + "</div>";
    this.itemTemplate = $('.box-sort-challenge-item-template').html();
    this.statement = challenge.configuration.statement;

    this.items = []

    challenge.configuration.group1.items.forEach(item => {
      this.items.push({
        group: 1,
        text: item.text,
        image: item.image,
      })
    });

    challenge.configuration.group2.items.forEach(item => {
      this.items.push({
        group: 2,
        text: item.text,
        image: item.image,
      })
    });

    this.group1Name = challenge.configuration.group1.name;
    this.group2Name = challenge.configuration.group2.name;

    this.items = shuffle(this.items);

    this.currentItemIndex = 0;
  }

  start = () => {
    let content = this.template.replaceAll('{{STATEMENT}}', this.statement);
    content = content.replaceAll('{{GROUP1_NAME}}', this.group1Name);
    content = content.replaceAll('{{GROUP2_NAME}}', this.group2Name);

    $('.challenge-container').html(window.parseVocables(content));
    bindModalOnVocableClick();
    $('.challenge-container .challenge-title').html(this.challenge.title);

    this.displayNextItem();

    const that = this;
    let timeout;
    $('.challenge-container .box').droppable({
      classes: {
        "ui-droppable-hover": "item-hover"
      },
      drop: function( _event, ui ) {
        clearTimeout(timeout);
        $('.challenge-container .effects img').removeClass('show');

        const currentItem = that.items[that.currentItemIndex];
        const box = $(this);
        const item = ui.draggable;
        const effects = $(box.parent().find('.effects'));

        timeout = setTimeout(() => {
          $('.challenge-container .effects img').removeClass('show');
        }, 2000);

        if (
          currentItem.group === 1 && box.hasClass('group2-box') ||
          currentItem.group === 2 && box.hasClass('group1-box')
        ) {
          window.loseLife();
          $(effects.find('.wrong-box')).addClass('show');
          item.addClass('wrong-answer');
          item.addClass('drag-revert');
          return false;
        }

        that.currentItemIndex += 1;

        $(effects.find('.sparkles')).addClass('show');
        item.addClass('dropped');

        setTimeout(() => {
          item.css('display', 'none');
        }, 500)

        that.displayNextItem();
      }
    });
  }

  displayNextItem = () => {
    const item = this.items[this.currentItemIndex];

    if (!item) {
      setTimeout(window.goToNextChallenge, 1000);
      return;
    }

    let image = '';
    let text = '';

    if (item.image) {
      image = `<img src="${getUrlPath(item.image)}" />`
    }

    if (item.text) {
      text = `<div class="text">${item.text}</div>`;
    }

    let itemHtml = `<div class='item item-${this.currentItemIndex}'>` + this.itemTemplate.replaceAll('{{ITEM_TEXT}}', text) + '</div>';
    itemHtml = itemHtml.replaceAll('{{ITEM_IMAGE}}', image);

    $('.challenge-container .item-area .items-wrapper').append(window.parseVocables(itemHtml));
    bindModalOnVocableClick();
    const element = $(`.challenge-container .item-${this.currentItemIndex}`);

    const updateItemHeight = function() {
      $('.challenge-container .item-area').css('height', (element.height() + 90) + 'px');
    }

    element.find('img').one('load', updateItemHeight);

    updateItemHeight();

    element.draggable({
      revert: function(valid) {
        if ($(this).hasClass('drag-revert') || !valid) {
          $(this).removeClass('drag-revert');
          return true;
        }
      }
    });
  }

  destroy = () => {
    this.items = [];
    $('.challenge-container .item').draggable('destroy');
    $('.challenge-container .box').droppable('destroy');
  }
}
