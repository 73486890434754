import {ChallengeLoader} from '../game/challenge/loader';

$(document).ready(function() {
  let adminChallengeLoader;
  if(document.getElementsByClassName('admin-challenge-preview').length > 0) {
    window.loadChallenges = function(challenges, lives) {
      adminChallengeLoader = new ChallengeLoader(challenges, lives);
      adminChallengeLoader.start();
    }

    window.goToNextChallenge = function() {
      adminChallengeLoader.goToNextChallenge();
    }

    window.finishedStage = function() {
      alert('Desafio Finalizado!');
      return adminChallengeLoader.reset();
    }

    window.loseLife = function() {
      return adminChallengeLoader.loseLife();
    }

    window.destroyChallenge = function() {
      if (adminChallengeLoader) {
        adminChallengeLoader.destroyCurrentChallenge();
      }
    }

    if (adminChallengeLoader) {
      adminChallengeLoader.destroyCurrentChallenge();
      adminChallengeLoader = null;
    }

    window.loadChallenges(window.challenges, window.numberOfLives)
    $('.challenge-top-bar .close-button').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    })
  }

  if(document.getElementsByClassName('admin-board-preview').length > 0) {
    $('.admin-board-preview .back-button, .admin-board-preview .stage-star').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    })
  }

  if(document.getElementsByClassName('admin-world-preview').length > 0) {
    $('.admin-world-preview .back-button, .admin-world-preview .board-selection-tile').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    })
  }


});
