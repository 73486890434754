export class TextChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='text-challenge'>" + $('.text-challenge-template').html() + "</div>";
    this.text = challenge.configuration.text;
    this.audioUrl = getUrlPath(challenge.configuration.audio);
    this.buttonText = challenge.configuration.button_text;
  }

  start = () => {
    let audioHtml = '';
    if (this.audioUrl) {
      audioHtml = this.buildAudio();
    }

    let content = this.template.replaceAll(
      '{{TEXT}}',
      "<div class='text-content " + (this.audioUrl ? 'has-audio' : '') + "'>" + this.text + "</div>"
    );
    content = content.replaceAll('{{AUDIO}}', audioHtml);

    $('.challenge-container').html(window.parseVocables(content));

    $('.challenge-container .challenge-title').html(this.challenge.title);

    bindModalOnVocableClick();

    $('.challenge-container .next-challenge-button').on('click', this.goToNextChallenge)

    $('.challenge-container .play-button').on('click', this.toggleAudio)

    if (this.audioUrl) {
      var audioElement = $('.challenge-container audio')[0];

      window.enableLoadingForAudio(audioElement);

      audioElement.addEventListener("timeupdate", this.updateAudioProgress)
    }

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }

    $('.challenge-container .text-content img').on('click', function() {
      window.openImageModal(this.src)
    })
  }

  destroy = () => {
    $('.challenge-container .text-content img').off('click');
    $('.challenge-container .next-challenge-button').off('click', this.goToNextChallenge);
  }

  updateAudioProgress = () => {
    const player = $('.challenge-container audio')[0];
    const currentTime = player.currentTime;
    const duration = player.duration;
    let percentage = (currentTime + 0.25) / duration * 100;

    if (percentage > 100) {
      percentage = 100;
    }

    if (percentage == 100) {
      $('.challenge-container .play-button').addClass('stopped')

      $('.global-loading-indicator').hide();
    }
  }

  toggleAudio = () => {
    $('.challenge-container .audio-container').addClass('started')
    $('.challenge-container .play-button').toggleClass('stopped')

    if($('.challenge-container .play-button').hasClass('stopped')){
      $('.challenge-container audio')[0].pause();
    } else {
      $('.challenge-container audio')[0].play();
    }
  }

  buildAudio = () => {
    return `
      <div class="audio-container">
        <link rel="preload" as="audio" href="${this.audioUrl}">

        <div class="play-button stopped"></div>

        <audio preload="auto">
          <source src="${this.audioUrl}">
        </audio>
      </div>
    `
  }

  goToNextChallenge = () => {
    window.goToNextChallenge();
  }
}
