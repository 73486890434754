import { shuffle } from "./shuffle";

const colors = shuffle([
  '#8e24aa', // purple
  '#ff4bf9', // pink
  '#1e88e5', // blue
  '#fdd834', // yellow
  '#23ffe9', // light blue
  '#44ff23', // green
]);

export const intToRGB = (value) => {
  return colors[value % colors.length];
}
