export class AudioChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='audio-challenge'>" + $('.audio-challenge-template').html() + "</div>";
    this.imageUrl = getUrlPath(challenge.configuration.image);
    this.audioUrl = getUrlPath(challenge.configuration.audio);
    this.text = challenge.configuration.text;
    this.buttonText = challenge.configuration.button_text;
  }

  start = () => {
    let audioHtml = '';
    if (this.audioUrl) {
      audioHtml = this.buildAudio();
    }

    let imageHtml = '';
    if (this.imageUrl) {
      imageHtml = `
        <div class="image-outer-container">
          <div class="image-container with-zoom-indicator">
            <img src="${this.imageUrl}" />
          </div>
        </div>
      `
    }

    let content = this.template.replaceAll('{{IMAGE}}', imageHtml);
    content = content.replaceAll('{{AUDIO}}', audioHtml);
    content = content.replaceAll('{{TEXT}}', this.text);

    $('.challenge-container').html(window.parseVocables(content));
    bindModalOnVocableClick()
    $('.challenge-container .challenge-title').html(this.challenge.title);

    if (this.imageUrl) {
      $('.challenge-container .avatar-listening').addClass('hidden');
    }

    $('.challenge-container .next-challenge-button').attr('disabled', true);
    $('.challenge-container .next-challenge-button').on('click', this.goToNextChallenge)
    $('.challenge-container .play-button').on('click', this.toggleAudio)
    $('.challenge-container .audio-progress').on('click', this.audioProgressClicked)

    if (this.audioUrl) {
      var audioElement = $('.challenge-container audio')[0];

      window.enableLoadingForAudio(audioElement);

      audioElement.addEventListener("timeupdate", this.updateAudioProgress)
    }

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }

    $('.challenge-container .image-container img').on('click', function() {
      window.openImageModal(this.src)
    })

    $('.challenge-container .next-challenge-button').attr('disabled', false);
  }

  destroy = () => {
    $('.challenge-container .image-container img').off('click');
    $('.challenge-container .play-button').off('click')
    $('.challenge-container .audio-progress').off('click', this.audioProgressClicked)
  }

  audioProgressClicked = (e) => {
    const percentage = e.offsetX / ($('.challenge-container .audio-progress').width() - 10) * 100;
    const player = $('.challenge-container audio')[0];

    player.currentTime = player.duration * (percentage / 100);
    $('.challenge-container .audio-progress .bar').width(percentage + '%');
  }

  updateAudioProgress = () => {
    const player = $('.challenge-container audio')[0];
    const currentTime = player.currentTime;
    const duration = player.duration;
    let percentage = (currentTime + 0.25) / duration * 100;

    if (percentage > 100) {
      percentage = 100;
    }

    $('.challenge-container .audio-progress .bar').width(percentage + '%');

    if (percentage == 100) {
      $('.challenge-container .play-button').addClass('stopped')

      $('.challenge-container .next-challenge-button').attr('disabled', false);
      $('.global-loading-indicator').hide();
    }
  }

  toggleAudio = () => {
    $('.challenge-container .audio-container').addClass('started')
    $('.challenge-container .play-button').toggleClass('stopped')

    if($('.challenge-container .play-button').hasClass('stopped')){
      $('.challenge-container audio')[0].pause();
    } else {
      $('.challenge-container audio')[0].play();
    }
  }

  buildAudio = () => {
    return `
      <div class="audio-container">
        <link rel="preload" as="audio" href="${this.audioUrl}">

        <div class="play-button stopped"></div>
        <div class="audio-progress-bar-container">
          <div class="audio-progress">
            <div class="bar"></div>
            <div class="circle"></div>
          </div>
        </div>
        <audio preload="auto">
          <source src="${this.audioUrl}">
        </audio>
      </div>
    `
  }

  goToNextChallenge = () => {
    window.goToNextChallenge()
  }
}
