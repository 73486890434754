$(document).on('turbolinks:load', function() {
  if (!$('.public-page')[0]) {
    return;
  }

  $('.open-menu').on('click', function() {
    $('.menu').addClass('open')
    $('body').addClass('menu-open')
  })

  $('.close-menu, .menu a').on('click', function() {
    $('.menu').removeClass('open')
    $('body').removeClass('menu-open')
  })
});
