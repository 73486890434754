export class UnityChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='unity-challenge'>" + $('.unity-challenge-template').html() + "</div>";
    this.buttonText = challenge.configuration.button_text;
    this.externalUrl = challenge.configuration.external_url;
  }

  start = () => {
    const content = this.template.replaceAll('{{URL}}', this.externalUrl);

    $('.challenge-container').html(window.parseVocables(content));
    bindModalOnVocableClick();

    $('.challenge-container .challenge-title').html(this.challenge.title);
    $('.challenge-container .next-challenge-button').on('click', this.goToNextChallenge)
    $('.challenge-container .play-overlay').on('click', this.playUnity)

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }
  }

  destroy = () => {
    $('.challenge-container .play-overlay').off('click', this.playUnity)
    $('.challenge-container .next-challenge-button').off('click', this.goToNextChallenge);
  }

  goToNextChallenge = () => {
    window.goToNextChallenge()
  }
}
