import { shuffle } from "../../utils/shuffle";

const OPTION_REGEX = /\/\*([^\*]+)\*\//
const IMAGE_REGEX = /\-\*([^\*]+)\*\-/

export class FillBlanksChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.items = challenge.configuration.items;
    this.images = {};
    if (challenge.configuration.images) {
      this.images = challenge.configuration.images.reduce((acc, el) => {
        acc[el.name.trim().toLowerCase()] = el.image;
        return acc;
      }, {})
    }

    this.template = "<div class='fill-blanks-challenge'>" + $('.fill-blanks-challenge-template').html() + "</div>";
    this.buttonText = challenge.configuration.button_text;
    this.options = []
  }

  start = () => {
    $('.challenge-container').html(window.parseVocables(this.template));
    bindModalOnVocableClick();
    $('.challenge-container .challenge-title').html(this.challenge.title);

    let i = 0;

    this.items.forEach(item => {
      let itemHtml = `<p class="item-${i}">${item}</p>`;
      let options = [];
      let imageOptions = [];

      while(OPTION_REGEX.exec(itemHtml)) {
        const result = OPTION_REGEX.exec(itemHtml);

        options.push({
          content: result[1],
          answer: result[1]
        });

        itemHtml = itemHtml.replace(result[0], '<span class="blank"></span>');
      }

      while(IMAGE_REGEX.exec(itemHtml)) {
        const result = IMAGE_REGEX.exec(itemHtml);
        const imageName = result[1].trim().toLowerCase()
        const answer = 'i-' + imageName;
        const url = this.images[imageName];

        if (url) {
          imageOptions.push({
            content: '<img src="' + url + '"/>',
            answer: answer
          });

          itemHtml = itemHtml.replace(result[0], '<span class="blank image-blank"></span>');
        } else {
          itemHtml = itemHtml.replace(result[0], '');
        }
      }


      $('.challenge-container .items').append(itemHtml);

      $(`.challenge-container .items .item-${i} .blank`).not('.image-blank').each((j, el) => {
        $(el).data('correctAnswer', options[j].answer);
      })

      $(`.challenge-container .items .item-${i} .image-blank`).each((j, el) => {
        $(el).data('correctAnswer', imageOptions[j].answer);
      })

      i += 1;

      this.options = this.options.concat(options)
      this.options = this.options.concat(imageOptions)
    })

    this.options = shuffle(this.options)

    this.options.forEach((option) => {
      const element = $(`<div class="option">${option.content}</div>`);
      $('.challenge-container .options-select .options').append(element);
      element.data('option', option);
    })

    $('.challenge-container .next-challenge-button').attr('disabled', true);
    $('.challenge-container .blank').on('click', this.selectBlank())
    $('.challenge-container .options .option').on('click', this.fillBlank())
    $('.challenge-container .next-challenge-button').on('click', this.checkAnswer)

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }
  }

  destroy = () => {
    $('.challenge-container .blank').off('click');
    $('.challenge-container .play-button').off('click')
    $('.challenge-container .audio-progress').off('click', this.audioProgressClicked)
  }

  fillBlank() {
    const that = this;

    return function() {
      const element = $(this);
      const selectedBlank = $('.challenge-container .blank.selected');
      const answer = element.data('option').answer;

      $('.challenge-container .blank').each((i, el) => {
        const blank = $(el)
        if (blank.data('answer') === answer) {
          that.resetBlank(blank)
        }
      })

      selectedBlank.data('answer', answer)

      selectedBlank.addClass('filled');
      selectedBlank.removeClass('selected');
      selectedBlank.html(element.data('option').content);

      $('.challenge-container .options-select').removeClass('open');
      $('.challenge-container .options-select').css('bottom', '');

      const hasSelectionCount = $('.challenge-container .blank.filled').length;
      const allCount = $('.challenge-container .blank').length;

      if (hasSelectionCount === allCount) {
        $('.challenge-container .next-challenge-button').attr('disabled', false);
      } else {
        $('.challenge-container .next-challenge-button').attr('disabled', true);
      }
    }
  }

  resetBlank(blank) {
    blank.removeClass('filled');
    blank.removeClass('selected');
    blank.html('');
    blank.data('answer', '')
  }

  checkAnswer = () => {
    let allCorrect = true;
    const that = this

    $('.blank').each((i, el) => {
      const blank = $(el)
      const correct = blank.data('answer') === blank.data('correctAnswer')

      if (!correct) {
        allCorrect = false
        that.resetBlank(blank)
        $('.challenge-container .next-challenge-button').attr('disabled', true);
      }
    })

    if (allCorrect) {
      return window.goToNextChallenge();
    }

    window.loseLife();
  }

  selectBlank() {
    return function() {
      const element = $(this);
      element[0].scrollIntoView();

      $('.challenge-container .blank').removeClass('selected');
      $('.challenge-container .options-select').addClass('open');

      setTimeout(() => {
        $('.challenge-container .options-select').css('bottom', '0');
      })

      element.addClass('selected');
    }
  }
}
