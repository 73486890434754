import { ChatbotChallenge } from './chatbot';
import { TextChallenge } from './text';
import { VideoChallenge } from './video';
import { VideoVimeoChallenge } from './video_vimeo';
import { AudioChallenge } from './audio';
import { CombinationChallenge } from './combination';
import { MultipleChoicesChallenge } from './multiple_choices';
import { FillBlanksChallenge } from './fill_blanks';
import { BoxSortChallenge } from './box_sort';
import { OrderItemsChallenge } from './order_items';
import { ImageWithDescriptionChallenge } from './image_with_description';
import { ChallengeInterlude } from './challenge_iterlude';
import { UnityChallenge } from './unity';
import { MemoryGameChallenge } from './memory_game';
import { PuzzleChallenge } from './puzzle';

export class ChallengeLoader {
  constructor(challenges, lives) {
    this.challenges = challenges;
    this.currentChallengeIndex = 0;
    this.currentLives = lives;
    this.originalLives = lives;
    this.challenge = null;
  }

  start() {
    $('.try-again-button').off('click');
    $('.try-again-button').on('click', () => this.reset());
    $('.challenge-top-bar .stat.lives .text').html(this.currentLives);

    this.currentChallengeIndex = 0;

    this.startCurrentChallenge();
  }

  destroyCurrentChallenge() {
    if (this.challenge) {
      this.challenge.destroy();
    }
  }

  goToNextChallenge() {
    if (this.challenge) {
      this.challenge.destroy();
    }

    if ($('.finished-challenge-modal').length && window.stageId) {
      const currentChallenge = this.challenges[this.currentChallengeIndex];
      if (currentChallenge) {
        $.ajax({
          type: "POST",
          url: '/challenge/' + currentChallenge.id + '/complete'
        });
      }
    }

    this.currentChallengeIndex += 1;

    this.startCurrentChallenge();
  }

  startCurrentChallenge() {
    const currentChallenge = this.challenges[this.currentChallengeIndex];

    if (!currentChallenge) {
      if (this.challenges && this.challenges.length > 0) {
        return window.finishedStage();
      }

      return;
    }

    this.challenge = this.buildChallenge(currentChallenge)

    this.challenge.start();

    window.startImageLoadingIndicator();

    this.updateProgress();

    $('*').scrollTop(0)
  }

  updateProgress() {
    const completionPercentage = ((this.currentChallengeIndex + 1) / this.challenges.length) * 100;

    $('.challenge-top-bar .progress').width(completionPercentage + '%')
  }

  buildChallenge(challenge) {
    switch (challenge.challenge_type) {
      case 'chatbot':
        return new ChatbotChallenge(challenge);
      case 'text':
        return new TextChallenge(challenge);
      case 'video':
        return new VideoChallenge(challenge);
      case 'video_vimeo':
        return new VideoVimeoChallenge(challenge);
      case 'audio':
        return new AudioChallenge(challenge);
      case 'multiple_choices':
        return new MultipleChoicesChallenge(challenge);
      case 'combination':
        return new CombinationChallenge(challenge);
      case 'fill_blanks':
        return new FillBlanksChallenge(challenge);
      case 'box_sort':
        return new BoxSortChallenge(challenge);
      case 'order_items':
        return new OrderItemsChallenge(challenge);
      case 'image_with_description':
        return new ImageWithDescriptionChallenge(challenge)
      case 'challenge_interlude':
        return new ChallengeInterlude(challenge)
      case 'unity':
        return new UnityChallenge(challenge)
      case 'memory_game':
        return new MemoryGameChallenge(challenge)
      case 'puzzle':
        return new PuzzleChallenge(challenge)
      default:
        // throw `unexpected challenge ${challenge.challenge_type}`
        console.error(`unexpected challenge ${challenge.challenge_type}`);
        window.goToNextChallenge();
    }
  }

  loseLife() {
    this.currentLives -= 1;
    $('.challenge-top-bar .stat.lives .text').html(this.currentLives);
    $('.challenge-top-bar .stat.lives').effect('shake', {distance: 10});

    if (this.currentLives <= 0) {
      if($('.game-over-modal').length) {
        $('.game-over-modal').addClass('show');

        const currentChallenge = this.challenges[this.currentChallengeIndex];

        if (currentChallenge) {
          $.ajax({
            type: "POST",
            url: '/challenge/' + currentChallenge.id + '/incomplete'
          });
        }
      } else {
        alert('Acabaram as vidas - Recomeçando');
        this.reset();
      }

      return false
    }

    return true;
  }

  reset() {
    this.currentChallengeIndex = 0;
    this.currentLives = this.originalLives;
    $('.challenge-top-bar .stat.lives .text').html(this.currentLives);
    $('.game-over-modal').removeClass('show');
    this.start();
  }
}

let loader;

window.finishedStage = function() {
  if ($('.finished-challenge-modal').length) {
    if (window.stageId) {
      $.ajax({
        type: "POST",
        url: '/stage/' + window.stageId + '/complete'
      });
    }

    return $('.finished-challenge-modal').addClass('show');
  }

  alert('Finalizado');
  return loader.reset();
}

window.loadChallenges = function(challenges, lives) {
  loader = new ChallengeLoader(challenges, lives);
  loader.start();
}

window.goToNextChallenge = function() {
  loader.goToNextChallenge();
}

window.loseLife = function() {
  return loader.loseLife();
}

window.destroyChallenge = function() {
  if (loader) {
    loader.destroyCurrentChallenge();
  }
}

document.addEventListener("turbolinks:load", function(event) {
  if (loader) {
    loader.destroyCurrentChallenge();
    loader = null;
  }

  if(document.getElementsByClassName('challenges-page').length > 0) {
    window.loadChallenges(window.challenges, window.numberOfLives)
  }
});
