export class ChatbotChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='chatbot-challenge'>" + $('.chatbot-challenge-template').html() + "</div>";
    this.messageTemplate = $('.chatbot-challenge-message-template').html();
    this.items = challenge.configuration;
    this.currentItemIndex = null;
  }

  start = () => {
    this.currentItemIndex = 0;

    $('.challenge-container').html(window.parseVocables(this.template));
    bindModalOnVocableClick();

    $('.challenge-container .challenge-title').html(this.challenge.title);

    $('.challenge-container .next-message-button').on('click', this.showNextMessage)

    this.displayCurrentItem();
  }

  destroy = () => {
    $('.challenge-container .next-message-button').off('click', this.showNextMessage);
    $('.challenge-container .chatbot-message img').off('click');
  }

  showNextMessage = () => {
    this.currentItemIndex += 1;

    if (this.currentItemIndex >= this.items.length) {
      return window.goToNextChallenge();
    }

    this.displayCurrentItem();
  }

  displayCurrentItem = () => {
    const item = this.items[this.currentItemIndex];
    let text = item.text || '';

    text = text.replace(/\&nbsp\;/gi, ' ');

    const message = this.messageTemplate.replaceAll('{{TEXT}}', text);

    $('.challenge-container .chatbot-messages').append(window.parseVocables("<div class='chatbot-message'>" + message + "</div>"));
    bindModalOnVocableClick();
    $('.challenge-container .next-message-button .button-content').html(item.button_text || 'Próximo')
    setTimeout(() => {
      $('.challenge-container .chatbot-message:last-child').addClass('open');

      if (navigator.vendor.startsWith('Apple')) {
        if ($('.challenge-container .chatbot-message').length != 1) {
          var offset = $('.challenge-container .chatbot-message:last-child').offset();
          $('html, body').animate({
              scrollTop: offset.top - 10,
          }, 1000);
        }
      } else {
        const container = $('.challenge-container .chatbot-messages');
        const scrollBuffer = $('.challenge-container .chatbot-message').length == 1 ? 40 : 30
        container.animate({
          scrollTop: container[0].scrollHeight - $('.challenge-container .chatbot-message:last-child').height() - scrollBuffer
        }, 1000);
      }

      $('.challenge-container .chatbot-message:last-child img').on('click', function() {
        window.openImageModal(this.src)
      })
    }, 100)


  }
}
