import {shuffle} from '../../utils/shuffle';

export class VideoChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='video-challenge'>" + $('.video-challenge-template').html() + "</div>";
    this.videoUrl = challenge.configuration.video;
    this.buttonText = challenge.configuration.button_text;

    this.questionTemplate = "<div class='video-challenge-question'>" + $('.video-challenge-question-template').html() + "</div>";
    this.questionItemTemplate = "<div class='video-challenge-question-item'>" + $('.video-challenge-question-item-template').html() + "</div>";

    this.questions = {};
    const items = challenge.configuration.items;

    this.items = []

    if (items) {
      items.forEach((item) => {
        if (item.time) {
          let time = 0;

          const splittedTime = item.time.split(':');

          splittedTime.reverse().forEach((timePart, i) => {
            time += parseInt(timePart, 10) * Math.pow(60, i)
          })

          item.parsedTime = time
          this.items.push(item)
        }
      })

      this.items.sort((a, b) => {
        return a.parsedTime - b.parsedTime;
      })

      this.items.forEach((item, i) => {
        item.index = i;

        while (this.questions[item.parsedTime]) {
          item.parsedTime += 1;
        }

        this.questions[item.parsedTime] = item
      })
    }
  }

  start = () => {
    const videoHtml = `
      <video class="disable-context-menu" controls preload="metadata" controlsList="nodownload">
        <source src="${getUrlPath(this.videoUrl)}#t=0.1">
      </video>
    `
    const content = this.template.replaceAll('{{VIDEO}}', videoHtml);

    $('.challenge-container').html(window.parseVocables(content));

    bindModalOnVocableClick();

    $('.challenge-container .next-challenge-button').attr('disabled', true);

    $('.challenge-container .challenge-title').html(this.challenge.title);
    $('.challenge-container .next-challenge-button').on('click', this.goToNextChallenge)
    $('.challenge-container .play-overlay').on('click', this.playVideo)

    $('.challenge-container video').on('ended', this.videoEnded)
    $('.disable-context-menu').on('contextmenu', function() { return false; });

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }

    this.items.forEach(item => {
      let contentHtml = this.questionTemplate.replaceAll('{{STATEMENT}}', item.statement)
      contentHtml = contentHtml.replaceAll('{{TIMESTAMP}}', item.time)
      const content = $(window.parseVocables(contentHtml));
      content.addClass(`question-${item.index}`);
      content.find('.question-timestamp').data('time', item.parsedTime);
      if (item.alternatives) {
        const alternatives = shuffle(item.alternatives);

        alternatives.forEach(alternative => {
          let text = ''

          if (alternative.text) {
            text = alternative.text
          }

          let alternativeHtml = this.questionItemTemplate.replaceAll('{{ITEM_TEXT}}', text);
          const alternativeElement = $(window.parseVocables(alternativeHtml));
          alternativeElement.data('is-correct', alternative.is_correct);

          content.find('.items').append(alternativeElement);
        })
      }

      $('.challenge-container .questions').append(content)
      bindModalOnVocableClick();
    })

    const that = this;

    $('.challenge-container .video-challenge-question-item').on('click', function() {
      that.selectAnswer(this)
    })

    $('.challenge-container video').on('timeupdate', function() {
      that.checkQuestionsForTimestamp(this)
    })

    $('.question-timestamp').on('click', function() {
      $('.challenge-container video')[0].currentTime = $(this).data('time');
    })

    $('.challenge-container .next-challenge-button').attr('disabled', false);
  }

  startQuestions = () => {
    $('.challenge-container .cinema-image-wrapper').addClass('hidden');
    $('.challenge-container .questions').removeClass('hidden');

    $('.challenge-container .questions').slick({
      dots: false,
      infinite: false,
      autoplay: false,
      arrows: false,
      adaptiveHeight: true,
      slidesToShow: 1,
      swipe: false,
      swipeToSlide: false,
      slidesToShow: 1
    });
  }

  destroy = () => {
    $('.challenge-container .next-challenge-button').off('click', this.goToNextChallenge);
    $('.challenge-container .play-overlay').off('click', this.playVideo)
    $('.challenge-container video').off('ended', this.videoEnded)

    if (!$('.challenge-container .questions').hasClass("hidden")) {
      $('.challenge-container .questions').slick('unslick');
    }

    $('.challenge-container video').off('timeupdate');
  }

  goToNextChallenge = () => {
    window.goToNextChallenge()
  }

  playVideo = () => {
    $('.challenge-container .play-overlay').hide();
    $('.challenge-container video')[0].play();
  }

  videoEnded = () => {
    $('.challenge-container .next-challenge-button').attr('disabled', false);
  }

  selectAnswer = (element) => {
    element = $(element);

    const question = element.closest('.video-challenge-question')

    if (question.find('.selected-correct').length) {
      return
    }

    if (element.hasClass('selected-error')) {
      return;
    }

    const isCorrect = element.data('is-correct');

    if (!isCorrect) {
      !window.isOldIos() && element.effect('shake', {distance: 30});
      element.addClass('selected-error')

      return;
    }

    question.find('.video-challenge-question-item').removeClass('selected-error');

    element.addClass('selected-correct');

    $('.video-challenge').animate({
      scrollTop: $(".video-challenge video").offset().top - 100
    }, 300);

    setTimeout(() => {
      $('.challenge-container video')[0].play();
    }, 300)
  }

  checkQuestionsForTimestamp = (videoElement) => {
    const question = this.questions[Math.floor(videoElement.currentTime)]

    if (!question) {
      $('.challenge-container .cinema-image-wrapper').removeClass('hidden');
      $('.challenge-container .questions').addClass('hidden');
      return;
    }

    if($('.challenge-container .questions').hasClass('hidden')) {
      this.startQuestions()
    }

    const questionElement = $(`.question-${question.index}`);

    if (!questionElement.hasClass('seen-question')) {
      videoElement.pause();

      try {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } catch (error) {
        // do nothing
      }
    }

    questionElement.addClass('seen-question');

    $('.challenge-container .questions').slick('slickGoTo', question.index);

    $('.video-challenge').animate({
      scrollTop: $(".video-challenge .questions").offset().top
    }, 300);
  }
}
