import { intToRGB } from '../../utils/int_to_rgb';
import { shuffle } from '../../utils/shuffle';

export class CombinationChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='combination-challenge'>" + $('.combination-challenge-template').html() + "</div>";
    this.itemTemplate = "<div class='combination-challenge-item'>" + $('.combination-challenge-item-template').html() + "</div>";
    this.buttonText = challenge.configuration.button_text;
    this.audioUrl = getUrlPath(challenge.configuration.audio);
    this.group1Name = challenge.configuration.group1_name;
    this.group2Name = challenge.configuration.group2_name;
    this.statement = challenge.configuration.statement;
    this.correctAnswers = {};
    this.currentSelection = 0;

    let i = 0;
    this.items = challenge.configuration.items;

    this.items.forEach(item => {
      item.id = i++;
    });
  }

  start = () => {
    let audioHtml = '';
    if (this.audioUrl) {
      audioHtml = this.buildAudio();
    }

    let content = this.template.replaceAll('{{STATEMENT}}', this.statement);
    content = content.replaceAll('{{AUDIO}}', audioHtml);
    content = content.replaceAll('{{GROUP1_NAME}}', this.group1Name);
    content = content.replaceAll('{{GROUP2_NAME}}', this.group2Name);

    $('.challenge-container').html(window.parseVocables(content));
    bindModalOnVocableClick();
    $('.challenge-container .challenge-title').html(this.challenge.title);

    let group1HasText = false

    shuffle(this.items).forEach((item, i) => {
      let image = '';
      let text = '';

      if (item.group1.image) {
        image = `<img src="${getUrlPath(item.group1.image)}" />`
      }

      if (item.group1.text) {
        text = item.group1.text;
        group1HasText = true;
      }

      let itemHtml = this.itemTemplate.replaceAll('{{ITEM_TEXT}}', text);
      itemHtml = itemHtml.replaceAll('{{ITEM_IMAGE}}', image);
      const group1ItemElement = $(window.parseVocables(itemHtml));
      group1ItemElement.data('id', item.id);
      group1ItemElement.data('group', 1);

      if (i % 2 == 0) {
        group1ItemElement.addClass('with-margin')
      }

      if (!image) {
        group1ItemElement.addClass('no-image')
      }

      $('.challenge-container .group-1 .items').append(group1ItemElement);
      bindModalOnVocableClick();
    });

    if (!group1HasText) {
      $('.challenge-container .group-1 .combination-challenge-item').addClass('image-only');

      $(".challenge-container .group-1 img").one("load", function() {
        var maxHeight = $(this).outerHeight();


        $('.challenge-container .group-1 .combination-challenge-item').each(function() {
          var height = $(this).outerHeight();

          if (height > maxHeight) {
            maxHeight = height
          }
        })

        $('.challenge-container .group-1 .combination-challenge-item .image').css("min-height", maxHeight - 12  + "px")
      }).each(function() {
        if(this.complete) {
          $(this).load();
        }
      });
    }

    let group2HasText = false

    shuffle(this.items).forEach((item, i) => {
      let image = '';
      let text = '';

      if (item.group2.image) {
        image = `<img src="${getUrlPath(item.group2.image)}" />`
      }

      if (item.group2.text) {
        text = item.group2.text
        group2HasText = true;
      }

      let itemHtml = this.itemTemplate.replaceAll('{{ITEM_TEXT}}', text);
      itemHtml = itemHtml.replaceAll('{{ITEM_IMAGE}}', image);
      const group2ItemElement = $(window.parseVocables(itemHtml));
      group2ItemElement.data('id', item.id);
      group2ItemElement.data('group', 2);

      if (i % 2 == 0) {
        group2ItemElement.addClass('with-margin')
      }

      if (!image) {
        group2ItemElement.addClass('no-image')
      }

      $('.challenge-container .group-2 .items').append(group2ItemElement);
      bindModalOnVocableClick();
    });

    if (!group2HasText) {
      $('.challenge-container .group-2 .combination-challenge-item').addClass('image-only');

      $(".challenge-container .group-2 img").one("load", function() {
        var maxHeight = $(this).outerHeight();


        $('.challenge-container .group-2 .combination-challenge-item').each(function() {
          var height = $(this).outerHeight();

          if (height > maxHeight) {
            maxHeight = height
          }
        })

        $('.challenge-container .group-2 .combination-challenge-item .image').css("min-height", maxHeight - 12  + "px")
      }).each(function() {
        if(this.complete) {
          $(this).load();
        }
      });
    }

    $('.challenge-container .next-challenge-button').attr('disabled', true);
    $('.challenge-container .combination-challenge-item').on('click', this.selectAnswer(this))
    $('.challenge-container .next-challenge-button').on('click', this.checkAnswer)

    $('.challenge-container .play-button').on('click', this.toggleAudio)
    $('.challenge-container .audio-progress').on('click', this.audioProgressClicked)
    if (this.audioUrl) {
      var audioElement = $('.challenge-container audio')[0];

      window.enableLoadingForAudio(audioElement);

      audioElement.addEventListener("timeupdate", this.updateAudioProgress)
    }

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }
  }

  destroy = () => {
    $('.challenge-container .combination-challenge-item').off('click')
    $('.challenge-container .next-challenge-button').off('click')
    $('.challenge-container .audio-progress').off('click', this.audioProgressClicked)
  }

  audioProgressClicked = (e) => {
    const percentage = e.offsetX / ($('.challenge-container .audio-progress').width() - 10) * 100;
    const player = $('.challenge-container audio')[0];
    player.currentTime = player.duration * (percentage / 100);
    $('.challenge-container .audio-progress .bar').width(percentage + '%');
  }

  updateAudioProgress = () => {
    const player = $('.challenge-container audio')[0];
    const currentTime = player.currentTime;
    const duration = player.duration;
    let percentage = (currentTime + 0.25) / duration * 100;

    if (percentage > 100) {
      percentage = 100;
    }

    $('.challenge-container .audio-progress .bar').width(percentage + '%');

    if (percentage == 100) {
      $('.challenge-container .play-button').addClass('stopped')
      $('.global-loading-indicator').hide();
    }
  }

  toggleAudio = () => {
    $('.challenge-container .audio-container').addClass('started')
    $('.challenge-container .play-button').toggleClass('stopped')

    if($('.challenge-container .play-button').hasClass('stopped')){
      $('.challenge-container audio')[0].pause();
    } else {
      $('.challenge-container audio')[0].play();
    }
  }

  buildAudio = () => {
    return `
      <div class="audio-container">
        <link rel="preload" as="audio" href="${this.audioUrl}">

        <div class="play-button stopped"></div>
        <div class="audio-progress-bar-container">
          <div class="audio-progress">
            <div class="bar"></div>
            <div class="circle"></div>
          </div>
        </div>
        <audio preload="auto">
          <source src="${this.audioUrl}">
        </audio>
      </div>
    `
  }

  checkAnswer = () => {
    let allCorrect = true;

    $('.challenge-container .combination-challenge-item.has-selection').each((i, el) => {
      el = $(el);

      const id = el.data('id');
      const matched = el.data('matchedId');

      allCorrect = allCorrect && (id === matched)
    })

    if (allCorrect) {
      return window.goToNextChallenge();
    }

    window.loseLife();
  }

  removeSelection(element, selection) {
    element.data('selection', null);
    element.data('matchedId', null);
    element.removeClass('has-selection');
    element.removeClass(`selection-${selection}`);
    element.css('border', '');
    element.css('border-left-color', '');
    element.css('border-left-width', '');
  }

  selectAnswer(that) {
    return function() {
      const element = $(this);

      if (element.hasClass('has-selection')) {
        return;
      }

      const selection = element.data('selection');

      if (selection || selection === 0) {
        $('.challenge-container .next-challenge-button').attr('disabled', true);
        const matched = $(`.challenge-container .selection-${selection}`);

        that.removeSelection(matched, selection)
      }

      const group = element.data('group');
      let otherGroup = 2;

      if (group == 2) {
        otherGroup = 1
      }

      let otherGroupSelection = $(`.challenge-container .group-${otherGroup} .combination-challenge-item.selected`)

      if (otherGroupSelection[0]) {
        element.data('matchedId', otherGroupSelection.data('id'));
        otherGroupSelection.data('matchedId', element.data('id'));

        $('.challenge-container .combination-challenge-item').removeClass('selected');
        if (element.data("id") !== otherGroupSelection.data("id")) {
          window.loseLife();
          if (!window.isOldIos()) {
            element.effect('shake', {distance: 10});
            otherGroupSelection.effect('shake', {distance: 10});
          }

          that.removeSelection(element, selection)
          that.removeSelection(otherGroupSelection, selection)
          element.css('border', "2px solid " + '#df083c');
          otherGroupSelection.css('border', "2px solid " + '#df083c');
          return;
        }

        element.data('selection', that.currentSelection);
        otherGroupSelection.data('selection', that.currentSelection);

        element.addClass(`selection-${that.currentSelection}`)
        otherGroupSelection.addClass(`selection-${that.currentSelection}`)
        element.addClass(`has-selection`);
        otherGroupSelection.addClass(`has-selection`);

        element.css('border', "1px solid " + intToRGB(that.currentSelection));
        otherGroupSelection.css('border', "1px solid " + intToRGB(that.currentSelection));
        element.css('border-left-width', '4px');
        otherGroupSelection.css('border-left-width', '4px');

        that.currentSelection += 1;

        const hasSelectionCount = $('.challenge-container .combination-challenge-item.has-selection').length;
        const allCount = $('.challenge-container .combination-challenge-item').length;

        if (hasSelectionCount === allCount) {
          $('.challenge-container .next-challenge-button').attr('disabled', false);
        }

        return;
      }

      $(`.challenge-container .group-${group} .combination-challenge-item`).removeClass('selected');
      element.addClass('selected');
    }
  }
}
