import {shuffle} from '../../utils/shuffle';

export class MultipleChoicesChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='multiple-choices-challenge'>" + $('.multiple-choices-challenge-template').html() + "</div>";
    this.itemTemplate = "<div class='multiple-choices-challenge-item'>" + $('.multiple-choices-challenge-item-template').html() + "</div>";
    this.buttonText = challenge.configuration.button_text;
    this.imageUrl = getUrlPath(challenge.configuration.image);
    this.audioUrl = getUrlPath(challenge.configuration.audio);
    this.statement = challenge.configuration.statement;
    this.items = shuffle(challenge.configuration.items);

    this.correctAnswers = [];
    this.hasText = false;
    this.selectedAnswer = '';
    let i = 0;

    this.items.forEach(item => {
      item.id = i++;

      if (item.is_correct) {
        this.correctAnswers.push(item.id);
      }

      if (item.text) {
        this.hasText = true;
      }
    });
  }

  start = () => {
    let audioHtml = '';
    if (this.audioUrl) {
      audioHtml = this.buildAudio();
    }

    let imageHtml = '';
    if (this.imageUrl) {
      imageHtml = `
        <div class="image-container with-zoom-indicator">
          <img src="${this.imageUrl}" />
        </div>
      `
    }

    let content = this.template.replaceAll('{{IMAGE}}', imageHtml);
    content = content.replaceAll('{{AUDIO}}', audioHtml);
    content = content.replaceAll('{{STATEMENT}}', this.statement);

    $('.challenge-container').html(window.parseVocables(content));
    $('.challenge-container .challenge-title').html(this.challenge.title);
    bindModalOnVocableClick();

    this.items.forEach(item => {
      let image = '';
      let text = '';

      if (item.image) {
        image = `<img src="${getUrlPath(item.image)}" />`
      }

      if (item.text) {
        text = item.text
      }

      let itemHtml = this.itemTemplate.replaceAll('{{ITEM_TEXT}}', text);
      itemHtml = itemHtml.replaceAll('{{ITEM_IMAGE}}', image);
      const itemElement = $(window.parseVocables(itemHtml));
      itemElement.data('id', item.id);

      if (!image) {
        itemElement.addClass('no-image')
      }

      $('.challenge-container .items').append(itemElement);
      bindModalOnVocableClick();
    })

    if (!this.hasText) {
      $('.challenge-container .multiple-choices-challenge-item').addClass('image-only');
    }

    $('.challenge-container .next-challenge-button').attr('disabled', true);
    $('.challenge-container .multiple-choices-challenge-item').on('click', this.selectAnswer(this))
    $('.challenge-container .next-challenge-button').on('click', this.checkAnswer)
    $('.challenge-container .play-button').on('click', this.toggleAudio)
    $('.challenge-container .audio-progress').on('click', this.audioProgressClicked)
    if (this.audioUrl) {
      var audioElement = $('.challenge-container audio')[0];

      window.enableLoadingForAudio(audioElement);

      audioElement.addEventListener("timeupdate", this.updateAudioProgress)
    }

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }

    $('.challenge-container .image-container img').on('click', function() {
      window.openImageModal(this.src)
    })
  }

  destroy = () => {
    $('.challenge-container .image-container img').off('click');
    $('.challenge-container .multiple-choices-challenge-item').off('click')
    $('.challenge-container .play-button').off('click')
    $('.challenge-container .audio-progress').off('click', this.audioProgressClicked)
  }

  audioProgressClicked = (e) => {
    const percentage = e.offsetX / ($('.challenge-container .audio-progress').width() - 10) * 100;
    const player = $('.challenge-container audio')[0];
    player.currentTime = player.duration * (percentage / 100);
    $('.challenge-container .audio-progress .bar').width(percentage + '%');
  }

  updateAudioProgress = () => {
    const player = $('.challenge-container audio')[0];
    const currentTime = player.currentTime;
    const duration = player.duration;
    let percentage = (currentTime + 0.25) / duration * 100;

    if (percentage > 100) {
      percentage = 100;
    }

    $('.challenge-container .audio-progress .bar').width(percentage + '%');

    if (percentage == 100) {
      $('.challenge-container .play-button').addClass('stopped')
      $('.global-loading-indicator').hide();
    }
  }

  toggleAudio = () => {
    $('.challenge-container .audio-container').addClass('started')
    $('.challenge-container .play-button').toggleClass('stopped')

    if($('.challenge-container .play-button').hasClass('stopped')){
      $('.challenge-container audio')[0].pause();
    } else {
      $('.challenge-container audio')[0].play();
    }
  }

  buildAudio = () => {
    return `
      <div class="audio-container">
        <link rel="preload" as="audio" href="${this.audioUrl}">

        <div class="play-button stopped"></div>
        <div class="audio-progress-bar-container">
          <div class="audio-progress">
            <div class="bar"></div>
            <div class="circle"></div>
          </div>
        </div>
        <audio preload="auto">
          <source src="${this.audioUrl}">
        </audio>
      </div>
    `
  }

  checkAnswer = () => {
    if (this.correctAnswers.includes(this.selectedAnswer)) {
      return window.goToNextChallenge();
    }

    window.loseLife();
  }

  selectAnswer(that) {
    return function() {
      if ($('.challenge-container .multiple-choices-challenge-item.selected-correct').length) {
        return
      }

      const element = $(this);

      if (element.hasClass('selected-error')) {
        return;
      }

      const answer = element.data('id');

      that.selectedAnswer = answer;

      if (!that.correctAnswers.includes(that.selectedAnswer)) {
        var shouldShake = !window.isOldIos();
        if (element.hasClass('image-only')) {
          element.find('img').attr('style', 'width: ' + element.width() + 'px !important');
          shouldShake && element.find('img').effect('shake', {distance: 30});
        } else {
          shouldShake && element.effect('shake', {distance: 30});
        }

        element.addClass('selected-error')
        window.loseLife();
        return;
      }

      $('.challenge-container .next-challenge-button').attr('disabled', false);
      $('.challenge-container .multiple-choices-challenge-item').removeClass('selected-error');
      element.addClass('selected-correct');
    }
  }
}
