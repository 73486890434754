$(document).on('turbolinks:load', function() {
  $('.worlds-page .worlds').slick({
    dots: false,
    infinite: true,
    autoplay: false,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>'
  });

  $('.worlds-page .completion-circle, .world-boards-page .completion-circle').circleProgress({
    startAngle: -Math.PI/2
  })
});
