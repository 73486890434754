var scrollToFirstStart = function() {
  if ($('.board-page')[0]) {
    $('.board-background').on('load',function() {
      const percentagePerStar = 0.2;

      $('.board-piece-wrapper').each(function() {
        const element = $(this);

        if (element.hasClass('resized')) {
          return
        }

        const starsCount = element.find('.stage-star').length

        element.height(element.height() * percentagePerStar * starsCount)
        element.addClass('resized');
      })

      setTimeout(function() {
        const stars = $('.stage-star').not('.completed');
        const lastStar = stars[stars.length - 1]

        if (lastStar) {
          $([document.documentElement, document.body]).animate({
            scrollTop: $(lastStar).offset().top - 350
          }, 1000);
        }
      }, 100)
    });
  }
}

$(document).on('ready', scrollToFirstStart);
$(document).on('turbolinks:load', scrollToFirstStart);
