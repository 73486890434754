$(document).on('turbolinks:load', () => {
  if ($('.first-login')[0]) {
    $('.first-login .go-to-next-page').on('click', function() {
      if (!$('#terms-accepted').is(':checked')) {
        alert('É necessário aceitar os termos de uso para continuar!')
        return false
      }
    })

  }
});
