export class ChallengeInterlude {
  constructor(challenge){
    this.challenge = challenge;
    this.template = $('.challenge-interlude-template').html();
    this.text = challenge.configuration.text;
    this.subText = challenge.configuration.sub_text;
    this.buttonText = challenge.configuration.button_text;
  }

  start = () => {
    let content = this.template.replaceAll('{{TEXT}}', this.text);

    if (this.subText) {
      content = content.replaceAll('{{SUB_TEXT}}', this.subText);
    } else {
      content = content.replaceAll('{{SUB_TEXT}}', '');
    }

    $('.challenge-container').html(window.parseVocables(content));
    bindModalOnVocableClick();
    $('.challenge-container .next-challenge-button').on('click', this.goToNextChallenge)

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }
  }

  destroy = () => {
    $('.challenge-container .next-challenge-button').off('click', this.goToNextChallenge);
  }

  goToNextChallenge = () => {
    window.goToNextChallenge();
  }
}
