export class ImageWithDescriptionChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='image-with-description-challenge'>" + $('.image-with-description-challenge-template').html() + "</div>";
    this.itemTemplate = "<div class='image-with-description-challenge-item'>" + $('.image-with-description-challenge-item-template').html() + "</div>";
    this.endingTemplate = "<div class='image-with-description-ending'>" + $('.image-with-description-ending-item-template').html() + "</div>";
    this.videoOverlayTemplate = $('.image-with-description-video-overlay-template').html();
    this.buttonText = challenge.configuration.button_text;
    this.endingText = challenge.configuration.ending_text;

    this.items = challenge.configuration.items.sort(function(a, b) {
      return a.order - b.order;
    });

    let i = 0;

    this.items.forEach(item => {
      item.id = i++;
    })
  }

  start = () => {
    $('.challenge-container').html(window.parseVocables(this.template));
    bindModalOnVocableClick();
    this.items.forEach((item) => {
      let image = '';
      let audio = '';
      let video = '';
      let text = '';
      let title = '';
      let onlyImage = false;

      if (item.video) {
        video = this.buildVideo(getUrlPath(item.video));
      } else {
        if (item.image) {
          onlyImage = true;
          image = `<img class="open-image-modal" src="${getUrlPath(item.image)}" />`
        }

        if (item.audio) {
          onlyImage = false
          audio = this.buildAudio(getUrlPath(item.audio));
        }
      }

      if (item.text) {
        text = item.text.replaceAll('&nbsp;', ' ')
      }

      if (item.title) {
        title = item.title
      }

      let itemHtml = this.itemTemplate.replaceAll('{{TEXT}}', text);
      itemHtml = itemHtml.replaceAll('{{TITLE}}', title);
      itemHtml = itemHtml.replaceAll('{{AUDIO}}', audio);
      itemHtml = itemHtml.replaceAll('{{IMAGE}}', image);
      itemHtml = itemHtml.replaceAll('{{VIDEO}}', video);
      const itemElement = $(`<div class="challenge-horizontal-margin">${window.parseVocables(itemHtml)}</div>`);

      if (item.audio) {
        itemElement.find('.scroll-container').addClass('with-audio');
      }

      if (onlyImage) {
        itemElement.find('.image-inner-wrapper').addClass('with-zoom-indicator');
      }

      itemElement.addClass(`item-${item.id}`)

      $('.challenge-container .items').append(itemElement);
      bindModalOnVocableClick();

      if (item.video) {
        $(`.challenge-container .item-${item.id} .play-overlay`).on('click', this.playVideo(item.id))
      } else {
        if (item.audio) {
          $(`.challenge-container .item-${item.id} .play-button`).on('click', this.toggleAudio(item.id))
          $(`.challenge-container .item-${item.id} .audio-progress`).on('click', this.audioProgressClicked(item.id))

          var audioElement = $(`.challenge-container .item-${item.id} audio`)[0];

          window.enableLoadingForAudio(audioElement);

          audioElement.addEventListener("timeupdate", this.updateAudioProgress)
        }
      }
    })

    let endingHtml = this.endingTemplate.replaceAll('{{TEXT}}', this.endingText);
    $('.challenge-container .items').append(window.parseVocables(endingHtml));
    bindModalOnVocableClick();

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }

    $('.challenge-container .next-challenge-button').on('click', this.goToNextChallenge)

    $('.challenge-container .items').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
      var i = (currentSlide ? currentSlide : 0) + 1;
      $('.challenge-container .slide-count').text(i + '/' + slick.slideCount);
    });

    $('.challenge-container .items').on('beforeChange', () => {
      this.carouselSlideChanged();
    });


    $('.challenge-container .items').slick({
      dots: false,
      infinite: false,
      autoplay: false,
      slidesToShow: 1,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',

      customPaging: function (slider, i) {
        return  (i + 1) + '/' + slider.slideCount;
      }
    });


    $('.disable-context-menu').on('contextmenu', function() { return false; });
    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }

    $('.challenge-container .open-image-modal').on('click', function() {
      window.openImageModal(this.src)
    })

    $('.challenge-container .overlay').on('click', function() {
      window.openImageModal($('.challenge-container .open-image-modal').attr('src'))
    })
  }

  destroy = () => {
    $('.challenge-container .items').off('beforeChange');
    $('.challenge-container .items').off('init reInit afterChange');
    $('.challenge-container .items').slick('unslick');
    $('.challenge-container .image-with-description-challenge-item').off('click')
    $('.challenge-container .play-button').off('click')
    $('.challenge-container .play-overlay').off('click')
    $('.challenge-container .audio-progress').off('click');
    $('.challenge-container .open-image-modal').off('click');
  }

  playVideo = (id) => () => {
    $(`.challenge-container .item-${id} .play-overlay`).hide();
    $(`.challenge-container .item-${id} video`)[0].play();
  }

  audioProgressClicked = (id) => (e) => {
    const percentage = e.offsetX / ($(`.challenge-container .item-${id} .audio-progress`).width() - 10) * 100;
    const player = $(`.challenge-container .item-${id} audio`)[0];
    player.currentTime = player.duration * (percentage / 100);
    $(`.challenge-container .item-${id} .audio-progress .bar`).width(percentage + '%');
  }

  updateAudioProgress = (id) => () => {
    const player = $(`.challenge-container .item-${id} audio`)[0];
    const currentTime = player.currentTime;
    const duration = player.duration;
    let percentage = (currentTime + 0.25) / duration * 100;

    if (percentage >= 100) {
      $(`.challenge-container .item-${id} .play-button`).addClass('stopped')
      $('.global-loading-indicator').hide();
    }
  }

  toggleAudio = (id) => () => {
    $(`.challenge-container .item-${id} .audio-container`).addClass('started')
    $(`.challenge-container .item-${id} .play-button`).toggleClass('stopped')

    if($(`.challenge-container .item-${id} .play-button`).hasClass('stopped')){
      $(`.challenge-container .item-${id} audio`)[0].pause();
    } else {
      $(`.challenge-container .item-${id} audio`)[0].play();
    }
  }

  carouselSlideChanged = () => {
    $(`.challenge-container audio, .challenge-container video`).each((i, item) => {
      item.pause();
    });
  }

  buildAudio = (audioUrl) => {
    return `
      <div class="overlay"></div>
      <div class="audio-container">
        <link rel="preload" as="audio" href="${audioUrl}">

        <div class="play-button stopped"></div>

        <audio preload="auto">
          <source src="${audioUrl}">
        </audio>
      </div>
    `;
  }

  buildVideo = (videoUrl) => {
    return `
      <div class="video-container">
        ${this.videoOverlayTemplate}

        <video class="disable-context-menu" controls preload="metadata" controlsList="nodownload">
          <source src="${videoUrl}#t=0.1">
        </video>
      </div>
    `;
  }

  goToNextChallenge = () => {
    return window.goToNextChallenge();
  }
}
