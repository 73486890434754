$(document).on('turbolinks:load', () => {
  if ($('.achievements-page')[0]) {
    $('.achievements-page .item').on('click', function() {
      const element = $(this);

      if (element.hasClass('empty')) {
        return;
      }

      const data = element.data();

      $('.achievement-modal .title').html(data.modalTitle)
      $('.achievement-modal .image-inner-wrapper').html('<img src="' + data.modalImage + '" />')
      $('.achievement-modal .text').html(data.modalDescription)
      $('.achievement-modal .inner-subtitle').html('<img src="' + data.modalWorldImage + '" />')
      $('.achievement-modal .inner-subtitle').append('<span>' + data.modalSubtitle + '</span>')

      $('.achievement-modal').addClass('open');
      $('.achievements-page').addClass('blur');
    })

    $('.achievement-modal .close-button').on('click', () => {
      $('.achievement-modal').removeClass('open');
      $('.achievements-page').removeClass('blur');
    })


  }
});
