
$(document).on('turbolinks:load', function() {
  $('#avatar-id').val($('.avatars-page .avatars-carousel .avatar-wrapper:first-child').data('avatar-id'));
  $('.avatars-page .avatars-carousel').slick({
    dots: false,
    infinite: true,
    autoplay: false,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>'
  });

  $('.avatars-page .avatars-carousel').on('afterChange', function(event, slick, currentSlide){
    $('#avatar-id').val($(slick.$slides.get(currentSlide)).data('avatar-id'));
  });
});
