import {shuffle} from '../../utils/shuffle';

export class VideoVimeoChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='video-vimeo-challenge'>" + $('.video-vimeo-challenge-template').html() + "</div>";
    this.videoUrl = challenge.configuration.video;
    this.buttonText = challenge.configuration.button_text;

    this.questionTemplate = "<div class='video-vimeo-challenge-question'>" + $('.video-vimeo-challenge-question-template').html() + "</div>";
    this.questionItemTemplate = "<div class='video-vimeo-challenge-question-item'>" + $('.video-vimeo-challenge-question-item-template').html() + "</div>";

    this.questions = {};
    const items = challenge.configuration.items;

    this.items = []

    if (items) {
      items.forEach((item) => {
        if (item.time) {
          let time = 0;

          const splittedTime = item.time.split(':');

          splittedTime.reverse().forEach((timePart, i) => {
            time += parseInt(timePart, 10) * Math.pow(60, i)
          })

          item.parsedTime = time
          this.items.push(item)
        }
      })

      this.items.sort((a, b) => {
        return a.parsedTime - b.parsedTime;
      })

      this.items.forEach((item, i) => {
        item.index = i;

        while (this.questions[item.parsedTime]) {
          item.parsedTime += 1;
        }

        this.questions[item.parsedTime] = item
      })
    }
  }

  start = () => {
    const content = this.template;

    $('.challenge-container').html(window.parseVocables(content));
    bindModalOnVocableClick();

    $('.challenge-container .next-challenge-button').attr('disabled', true);

    $('.challenge-container .challenge-title').html(this.challenge.title);
    $('.challenge-container .next-challenge-button').on('click', this.goToNextChallenge)
    $('.challenge-container .play-overlay').on('click', this.playVideo)
    $('.disable-context-menu').on('contextmenu', function() { return false; });

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }

    this.items.forEach(item => {
      let contentHtml = this.questionTemplate.replaceAll('{{STATEMENT}}', item.statement)
      contentHtml = contentHtml.replaceAll('{{TIMESTAMP}}', item.time)
      const content = $(window.parseVocables(contentHtml));
      content.addClass(`question-${item.index}`);
      content.find('.question-timestamp').data('time', item.parsedTime);
      if (item.alternatives) {
        const alternatives = shuffle(item.alternatives);

        alternatives.forEach(alternative => {
          let text = ''

          if (alternative.text) {
            text = alternative.text
          }

          let alternativeHtml = this.questionItemTemplate.replaceAll('{{ITEM_TEXT}}', text);
          const alternativeElement = $(window.parseVocables(alternativeHtml));
          alternativeElement.data('is-correct', alternative.is_correct);

          content.find('.items').append(alternativeElement);
        })
      }

      $('.challenge-container .questions').append(content)
      bindModalOnVocableClick();
    })

    const that = this;
    const width = $('#vimeo-video').width();
    const height = (width / 16) * 9;
    $('#vimeo-video').height(height)
    this.videoPlayer = new Vimeo.Player('vimeo-video', {
      url: this.videoUrl,
      width: width,
      height: height,
    });

    $(window).on('resize', this.onWindowResize);

    this.videoPlayer.on('ended', this.videoEnded);

    this.videoPlayer.on('timeupdate', function(event) {
      that.checkQuestionsForTimestamp(event)
    });

    $('.challenge-container .video-vimeo-challenge-question-item').on('click', function() {
      that.selectAnswer(this)
    })

    $('.question-timestamp').on('click', function() {
      $('.challenge-container video')[0].currentTime = $(this).data('time');
    })

    $('.challenge-container .next-challenge-button').attr('disabled', false);
  }

  onWindowResize = () => {
    const width = $('#vimeo-video').width();
    const height = (width / 16) * 9;
    $('#vimeo-video').height(height)
  }

  startQuestions = () => {
    $('.challenge-container .cinema-image-wrapper').addClass('hidden');
    $('.challenge-container .questions').removeClass('hidden');

    $('.challenge-container .questions').slick({
      dots: false,
      infinite: false,
      autoplay: false,
      arrows: false,
      adaptiveHeight: true,
      slidesToShow: 1,
      swipe: false,
      swipeToSlide: false,
      slidesToShow: 1
    });
  }

  destroy = () => {
    this.videoPlayer.unload()

    $('.challenge-container .next-challenge-button').off('click', this.goToNextChallenge);
    $('.challenge-container .play-overlay').off('click', this.playVideo)

    if (!$('.challenge-container .questions').hasClass("hidden")) {
      $('.challenge-container .questions').slick('unslick');
    }

    $(window).off('resize', this.onWindowResize);
  }

  goToNextChallenge = () => {
    window.goToNextChallenge()
  }

  playVideo = () => {
    $('.challenge-container .play-overlay').hide();
    this.videoPlayer.play();
    // this.videoPlayer.requestFullscreen();
  }

  videoEnded = () => {
    $('.challenge-container .next-challenge-button').attr('disabled', false);

    try {
      this.videoPlayer.exitFullscreen();
    } catch (error) {
      // do nothing
    }

    $('.video-vimeo-challenge').animate({
      scrollTop: $('.challenge-container .next-challenge-button').offset().top - 100
    }, 300);
  }

  selectAnswer = (element) => {
    element = $(element);

    const question = element.closest('.video-vimeo-challenge-question')

    if (question.find('.selected-correct').length) {
      return
    }

    if (element.hasClass('selected-error')) {
      return;
    }

    const isCorrect = element.data('is-correct');

    if (!isCorrect) {

      !window.isOldIos() && element.effect('shake', {distance: 30});
      element.addClass('selected-error')

      return;
    }

    question.find('.video-vimeo-challenge-question-item').removeClass('selected-error');

    element.addClass('selected-correct');

    $('.video-vimeo-challenge').animate({
      scrollTop: $(".video-vimeo-challenge iframe").offset().top - 100
    }, 300);

    setTimeout(() => {
      this.playVideo()
    }, 300)
  }

  checkQuestionsForTimestamp = (event) => {
    const question = this.questions[Math.floor(event.seconds)]

    if (!question) {
      $('.challenge-container .cinema-image-wrapper').removeClass('hidden');
      $('.challenge-container .questions').addClass('hidden');
      return;
    }

    if($('.challenge-container .questions').hasClass('hidden')) {
      this.startQuestions()
    }

    const questionElement = $(`.question-${question.index}`);
    const seenQuestion = questionElement.hasClass('seen-question');

    if (!seenQuestion) {
      this.videoPlayer.pause();

      try {
        this.videoPlayer.exitFullscreen();
      } catch (error) {
        // do nothing
      }
    }

    questionElement.addClass('seen-question');

    $('.challenge-container .questions').slick('slickGoTo', question.index);

    if (!seenQuestion) {
      $('.video-vimeo-challenge').animate({
        scrollTop: $(".video-vimeo-challenge .questions").offset().top
      }, 300);
    }
  }
}
