import { ExplanationModal } from '../common/explanation_modal';

export class PuzzleChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='puzzle-challenge'>" + $('.puzzle-challenge-template').html() + "</div>";
    this.buttonText = challenge.configuration.button_text;
    this.imageUrl = challenge.configuration.image;
    this.pieces = parseInt(challenge.configuration.pieces, 10);

    if (challenge.configuration.show_explanation == 'true') {
      this.modalData = {
        explanation: {
          items: [
            {
              audio: challenge.configuration.modal_audio,
              id: 'id',
              image: challenge.configuration.modal_image,
              text: challenge.configuration.modal_text,
              title: challenge.configuration.modal_title,
              video: challenge.configuration.modal_video,
            }
          ]
        }
      }
    }
  }

  start = () => {
    let content = this.template.replaceAll('{{TITLE}}', this.challenge.title);

    $('.challenge-container').html(window.parseVocables(content));
    bindModalOnVocableClick();
    $('.challenge-container .next-challenge-button').attr('disabled', true);
    $('.challenge-container .next-challenge-button').on('click', this.goToNextChallenge);

    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }

    let image = new Image();

    image.src = this.imageUrl;

    const width = $('#puzzle-canvas').width();
    let pieces = this.pieces;

    const ratio = (width / 700)
    const height = 650 * ratio;
    let pieceSize;
    switch (pieces) {
      case 5:
        pieceSize = 72
        break;
      case 4:
        pieceSize = 100
        break;
      default:
        pieceSize = 140
        break;
    }

    image.onload = () => {
      this.puzzle = new headbreaker.Canvas('puzzle-canvas', {
        width: 700, height: 650,
        pieceSize: pieceSize, proximity: 20,
        borderFill: 10, strokeWidth: 2,
        lineSoftness: 0.12, image: image,
        fixed: true,
        preventOffstageDrag: true
      });

      this.puzzle.adjustImagesToPuzzleHeight();
      this.puzzle.autogenerate({
        horizontalPiecesCount: pieces,
        verticalPiecesCount: pieces,
        insertsGenerator: headbreaker.generators.flipflop
      });
      this.puzzle.shuffleGrid();

      this.puzzle.attachSolvedValidator();
      this.puzzle.onValid(() => {
        if (this.modalData) {
          var modal = new ExplanationModal(this.modalData, {
            text: this.buttonText || 'Continuar',
            onClick: this.goToNextChallenge
          });
          modal.start()
        }

        $('.challenge-container .next-challenge-button').attr('disabled', false);
      })

      this.puzzle.resize(width, height);
      this.puzzle.scale(ratio);
      this.puzzle.draw();
      $('#puzzle-canvas > .konvajs-content, #puzzle-canvas canvas').width('100%')
    }
  }

  goToNextChallenge = () => {
    window.goToNextChallenge()
  }

  destroy = () => {
    this.puzzle.clear();
    $('.challenge-container .next-challenge-button').off('click', this.goToNextChallenge);
  }
}
