import { ExplanationModal } from './explanation_modal';

window.bindModalOnVocableClick = function() {
  $('.vocable-link').off('click');
  $('.vocable-link').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    var element = $(this);
    const term = element.data('vocable');

    $.get('/termo', {term: term}, function(data) {
      var modal = new ExplanationModal(data);
      modal.start()
    }).fail(function() {
      alert('Não foi possível buscar as informações deste verbete!')
    });
  })
}
