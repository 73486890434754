export class ExplanationModal {
  constructor(vocable, bottomButton) {
    this.vocable = vocable;
    this.bottomButton = bottomButton
    this.template = "<div class='image-with-description-challenge'>" + $('.vocable-modal-template').html() + "</div>";
    this.itemTemplate = "<div class='image-with-description-challenge-item'>" + $('.vocable-modal-item-template').html() + "</div>";
    this.videoOverlayTemplate = $('.vocable-modal-video-overlay-template').html();

    this.items = vocable.explanation.items.sort(function(a, b) {
      return a.order - b.order;
    });

    let i = 0;

    this.items.forEach(item => {
      item.id = i++;
    })
  }

  start = () => {
    $(document.body).append("<div class='vocables-modal'></div>")
    $('.vocables-modal').html(window.parseVocables(this.template));
    bindModalOnVocableClick();
    this.items.forEach((item) => {
      let image = '';
      let audio = '';
      let video = '';
      let text = '';
      let title = '';
      let onlyImage = false;

      if (item.video) {
        video = this.buildVideo(getUrlPath(item.video));
      } else {
        if (item.image) {
          onlyImage = true;
          image = `<img class="open-image-modal" src="${getUrlPath(item.image)}" />`
        }

        if (item.audio) {
          onlyImage = false
          audio = this.buildAudio(getUrlPath(item.audio));
        }
      }

      if (item.text) {
        text = item.text.replaceAll('&nbsp;', ' ')
      }

      if (item.title) {
        title = item.title
      }

      let itemHtml = this.itemTemplate.replaceAll('{{TEXT}}', text);
      itemHtml = itemHtml.replaceAll('{{TITLE}}', title);
      itemHtml = itemHtml.replaceAll('{{AUDIO}}', audio);
      itemHtml = itemHtml.replaceAll('{{IMAGE}}', image);
      itemHtml = itemHtml.replaceAll('{{VIDEO}}', video);
      const itemElement = $(`<div class="challenge-horizontal-margin">${window.parseVocables(itemHtml)}</div>`);

      if (onlyImage) {
        itemElement.find('.image-inner-wrapper').addClass('with-zoom-indicator');
      }

      itemElement.addClass(`item-${item.id}`)

      $('.vocables-modal .items').append(itemElement);
      bindModalOnVocableClick();

      if (item.video) {
        $(`.vocables-modal .item-${item.id} .play-overlay`).on('click', this.playVideo(item.id))
      } else {
        if (item.audio) {
          $(`.vocables-modal .item-${item.id} .play-button`).on('click', this.toggleAudio(item.id))
          $(`.vocables-modal .item-${item.id} .audio-progress`).on('click', this.audioProgressClicked(item.id))

          var audioElement = $(`.vocables-modal .item-${item.id} audio`)[0];

          window.enableLoadingForAudio(audioElement);

          audioElement.addEventListener("timeupdate", this.updateAudioProgress)
        }
      }
    })

    bindModalOnVocableClick();

    $('.vocables-modal .items').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
      var i = (currentSlide ? currentSlide : 0) + 1;
      $('.vocables-modal .slide-count').text(i + '/' + slick.slideCount);
    });

    $('.vocables-modal .items').on('beforeChange', () => {
      this.carouselSlideChanged();
    });


    $('.vocables-modal .items').slick({
      dots: false,
      infinite: false,
      autoplay: false,
      slidesToShow: 1,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',

      customPaging: function (slider, i) {
        return  (i + 1) + '/' + slider.slideCount;
      }
    });


    $('.disable-context-menu').on('contextmenu', function() { return false; });

    $('.vocables-modal .open-image-modal').on('click', function() {
      window.openImageModal(this.src)
    })

    $('.vocables-modal .close-button').on('click', () => this.destroy())

    if (this.bottomButton) {
      $('.vocables-modal').append(
        '<button class="bottom-button" type="button">' +
          '<span class="button-content">' +
            this.bottomButton.text +
          '</span>' +
        '</button>'
      )

      $('.vocables-modal .bottom-button').on('click', () => {
        if (this.bottomButton.onClick) {
          this.bottomButton.onClick()
        }

        this.destroy()
      })
    }
  }

  destroy = () => {
    $('.vocables-modal').fadeOut(400, function() {
      $('.vocables-modal .items').off('beforeChange');
      $('.vocables-modal .items').off('init reInit afterChange');
      $('.vocables-modal .items').slick('unslick');
      $('.vocables-modal .vocable-modal-item').off('click')
      $('.vocables-modal .play-button').off('click')
      $('.vocables-modal .play-overlay').off('click')
      $('.vocables-modal .audio-progress').off('click');
      $('.vocables-modal .open-image-modal').off('click');

      $('.vocables-modal').remove()
    })
  }

  playVideo = (id) => () => {
    $(`.vocables-modal .item-${id} .play-overlay`).hide();
    $(`.vocables-modal .item-${id} video`)[0].play();
  }

  audioProgressClicked = (id) => (e) => {
    const percentage = e.offsetX / ($(`.vocables-modal .item-${id} .audio-progress`).width() - 10) * 100;
    const player = $(`.vocables-modal .item-${id} audio`)[0];
    player.currentTime = player.duration * (percentage / 100);
    $(`.vocables-modal .item-${id} .audio-progress .bar`).width(percentage + '%');
  }

  updateAudioProgress = (id) => () => {
    const player = $(`.vocables-modal .item-${id} audio`)[0];
    const currentTime = player.currentTime;
    const duration = player.duration;
    let percentage = (currentTime + 0.25) / duration * 100;

    if (percentage > 100) {
      percentage = 100;
    }

    $(`.vocables-modal .item-${id} .audio-progress .bar`).width(percentage + '%');

    if (percentage == 100) {
      $(`.vocables-modal .item-${id} .play-button`).addClass('stopped')
      $('.global-loading-indicator').hide();
    }
  }

  toggleAudio = (id) => () => {
    $(`.vocables-modal .item-${id} .audio-container`).addClass('started')
    $(`.vocables-modal .item-${id} .play-button`).toggleClass('stopped')

    if($(`.vocables-modal .item-${id} .play-button`).hasClass('stopped')){
      $(`.vocables-modal .item-${id} audio`)[0].pause();
    } else {
      $(`.vocables-modal .item-${id} audio`)[0].play();
    }
  }

  carouselSlideChanged = () => {
    $(`.vocables-modal audio, .vocables-modal video`).each((i, item) => {
      item.pause();
    });
  }

  buildAudio = (audioUrl) => {
    return `
      <div class="overlay"></div>
      <div class="audio-container">
        <link rel="preload" as="audio" href="${audioUrl}">

        <div class="play-button stopped"></div>
        <div class="audio-progress-bar-container">
          <div class="audio-progress">
            <div class="bar"></div>
            <div class="circle"></div>
          </div>
        </div>
        <audio preload="auto">
          <source src="${audioUrl}">
        </audio>
      </div>
    `;
  }

  buildVideo = (videoUrl) => {
    return `
      <div class="video-container">
        ${this.videoOverlayTemplate}

        <video class="disable-context-menu" controls preload="metadata" controlsList="nodownload">
          <source src="${videoUrl}#t=0.1">
        </video>
      </div>
    `;
  }
}
