$(document).on('turbolinks:load', function() {
  if (!$('.faq-page')[0]) {
    return;
  }

  $('.faq h2').on('click', function() {
    const parent = $(this).parent();

    parent.toggleClass('closed');

    if(parent.hasClass('closed')) {
      parent.find('.faq-content').height(0)
    } else {
      parent.find('.faq-content').height(parent.find('.faq-inner-content').outerHeight() + 20)
    }
  })
});
