// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(str, newStr){

		// If a regex pattern
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}

		// If a string
		return this.replace(new RegExp(str, 'g'), newStr);

	};
}

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");

global.$ = $
global.jQuery = $

require("jquery-ui-dist/jquery-ui");

require("./vendor/imagesloaded.pkgd");
require("./vendor/jquery-ui-touch-puch");
require("./vendor/headbreaker");
require("./game/common/pwa");
require("./game/common/functions");
require("./game/common/loading-indicator");
require("./game/common/achievement_modal");
require("./game/common/vocables_modal");
require("./game/first_login/form");
require("./game/achievements/modal");
require("./game/challenge/loader");
require("./game/avatars/selection");
require("./game/worlds/list");
require("./game/boards/board");

require("./public/header");
require("./public/faq");

require("./admin/game_preview");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
