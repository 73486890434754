window.openImageModal = function(src) {
  $.fancybox.open({
    protect: true,
    src: src,
    btnTpl: {
      zoom:
        '<button data-fancybox-zoom class="custom-fancybox-button fancybox-button--zoom" title="{{ZOOM}}">' +
          '<span class="button-content">' +
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.7 17.3l-3-3a5.9 5.9 0 0 0-.6-7.6 5.9 5.9 0 0 0-8.4 0 5.9 5.9 0 0 0 0 8.4 5.9 5.9 0 0 0 7.7.7l3 3a1 1 0 0 0 1.3 0c.4-.5.4-1 0-1.5zM8.1 13.8a4 4 0 0 1 0-5.7 4 4 0 0 1 5.7 0 4 4 0 0 1 0 5.7 4 4 0 0 1-5.7 0z"/></svg>' +
          '</span>' +
        "</button>",

      close:
        '<button data-fancybox-close class="custom-fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
          '<span class="button-content">' +
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"/></svg>' +
          '</span>' +
        "</button>",
    }
  })
}

window.getUrlPath = function(urlString) {
  try {
    const url = new URL(urlString);

    const origin = url.origin;

    return urlString.replace(origin, '')
  } catch (error) {
    return urlString;
  }
}

window.startImageLoadingIndicator = function() {
  $('.global-loading-indicator').show();

  $(document.body).imagesLoaded().always( function( instance ) {
    $('.global-loading-indicator').hide();
  });
}

window.enableLoadingForAudio = function(element) {
  var loadingEvents = [
    'loadstart',
    'stalled',
    'waiting'
  ];

  loadingEvents.forEach(function(event) {
    element.addEventListener(event, function() {
      $('.global-loading-indicator').show();
    });
  })

  var loadedEvents = [
    'canplay',
    'canplaythrough'
  ]

  loadedEvents.forEach(function(event) {
    element.addEventListener(event, function() {
      $('.global-loading-indicator').hide();
    });
  })
}

window.parseVocables = function(html) {
  var regexp = /\%\*(.*?)\*\%/gi;

  var matches_array = html.match(regexp);

  if (!matches_array) {
    return html;
  }

  matches_array.forEach(element => {
    var clearedText = element.replace("%*", "").replace("*%", "")
    html = html.replace(element, "<a href='#' class='vocable-link' data-vocable=\"" + encodeURI(clearedText) + "\">" + clearedText + "</a>")
  });

  return html;
}

window.isOldIos = function() {
  try {
    var isIos = (/iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.userAgent.includes('Macintosh'))
    return isIos && navigator.maxTouchPoints === undefined;
  } catch (error) {
    return true;
  }
}
