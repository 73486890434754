export const shuffle = (array, times = 0) => {
  if (array.length <= 1 || times >= 11) {
    return array
  }

  let currentIndex = array.length, temporaryValue, randomIndex;

  const newArray = [...array]

  while (0 !== currentIndex) {

    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = newArray[currentIndex];
    newArray[currentIndex] = newArray[randomIndex];
    newArray[randomIndex] = temporaryValue;
  }

  let allEqual = true;

  array.forEach((element, i) => {
    allEqual = allEqual && element == newArray[i]
  });

  return allEqual ? shuffle(array, times + 1) : newArray;
}
