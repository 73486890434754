import { shuffle } from '../../utils/shuffle';

export class OrderItemsChallenge {
  constructor(challenge){
    this.challenge = challenge;
    this.template = "<div class='order-items-challenge'>" + $('.order-items-challenge-template').html() + "</div>";
    this.itemTemplate = "<div class='order-items-challenge-item'>" + $('.order-items-challenge-item-template').html() + "</div>";

    this.buttonText = challenge.configuration.button_text;

    this.items = challenge.configuration.items.sort(function(a, b) {
      return a.order - b.order;
    });

    this.statement = challenge.configuration.statement;
  }

  start = () => {
    let content = this.template.replaceAll('{{STATEMENT}}', this.statement);

    $('.challenge-container').html(window.parseVocables(content));
    $('.challenge-container .challenge-title').html(this.challenge.title);
    bindModalOnVocableClick();

    shuffle([...this.items]).forEach(item => {
      let image = '';

      if (item.image) {
        image = `<img src="${getUrlPath(item.image)}" />`
      }

      const itemHtml = this.itemTemplate.replaceAll('{{ITEM_IMAGE}}', image);
      const itemElement = $(window.parseVocables(itemHtml));
      itemElement.data('order', item.order);
      $('.challenge-container .items').append(itemElement);
      bindModalOnVocableClick();
    });


    if (this.buttonText) {
      $('.challenge-container .next-challenge-button .button-content').html(this.buttonText);
    }

    $('.challenge-container .items').sortable({
      start: function() {
        $('.challenge-container .order-items-challenge-item').removeClass('wrong-answer');
        $('.challenge-container .next-challenge-button').attr('disabled', false);
      }
    });
    $('.challenge-container .items').disableSelection();
    $('.challenge-container .next-challenge-button').attr('disabled', 'disabled');
    $('.challenge-container .next-challenge-button').on('click', this.checkAnswer)
  }

  destroy = () => {
    $('.challenge-container .next-challenge-button').off('click')
  }

  checkAnswer = () => {
    let allCorrect = true;

    $('.challenge-container .order-items-challenge-item').each((i, el) => {
      el = $(el);
      const order = el.data('order');

      if (order != this.items[i].order) {
        allCorrect = false;
        el.addClass('wrong-answer');
      }
    })

    if (allCorrect) {
      return window.goToNextChallenge();
    }

    window.loseLife();
  }
}
